<template>
    <v-container ref="wrapper" class="grey lighten-5">
        <SpinnerComponent :spinning="products.isLoading" text="Cargando productos..." />
        <v-card>
            <v-card-title>
                <div class="d-flex justify-space-between align-center flex-wrap" style="width: 100%; gap: 16px;">
                    <h3 class="text-h6 font-weight-bold">
                        <NavButton style="transform: translateY(-2px); margin-right: 4px !important;" />
                        {{ nombreFamilia }}
                    </h3>
                    <div class="d-flex align-center flex-wrap" style="gap: 16px;">
                        <v-btn 
                            class="flex-grow-1 flex-md-grow-0" 
                            color="secondary" 
                            :to="'/cm-contactos-proveedor/' + familyId" 
                            outlined
                        >
                            <v-icon color="secondary" class="mr-2">mdi-account-box</v-icon>
                            Contactos
                        </v-btn>
                        <v-btn 
                            v-if="circularFamilia"
                            @click.stop="visualizarCircular"
                            :loading="visualizacionCircular.isLoading"
                            class="flex-grow-1 flex-md-grow-0" 
                            color="secondary" 
                            outlined
                        >
                            <v-icon color="secondary" class="mr-2">mdi-file-pdf-box</v-icon>
                            Circular
                        </v-btn>
                    </div>
                </div>
            </v-card-title>
            <v-divider />
            <v-card-text>
                <RequestStatusFeedbackComponent class="d-block" />

                <ProductViewFiltersComponent 
                    :groupsLoadable="groups" 
                    :subGroupsLoadable="subgroups"
                    :isNameLoading="products.isLoading"
                    :values="filters"
                    @filter-change="handleFilterChange"
                    @reset="handleFilterReset"
                />
                <EmptyComponent 
                    :empty="!products.isLoading && !products.data.length" 
                    text="No se encontraron productos."
                >
                    <v-row>
                        <v-col cols="12" sm="6" md="4" lg="3" xl="2" v-for="product in products.data" :key="product.id">
                            <ProductCardComponent :product="product" />
                        </v-col>
                    </v-row>
                    <PaginationComponent 
                        v-if="products.data.length > 0"
                        class="mt-10"
                        :total="products.pagination.total_rows" 
                        :pagination="pagination"
                    />
                </EmptyComponent>
            </v-card-text>
        </v-card>

        <PdfModal
            :isOpen="visualizacionCircularAbierta" 
            :source-loadable="visualizacionCircular" 
            @on-visibility-change="manejarVisibilidadVisualizacionCircular"
            :filename="`circular${nombreFamilia ? '-' + nombreFamilia : ''}`"
        />
    </v-container>
</template>
<script>
import { EmptyComponent, SpinnerComponent, PaginationComponent, NavButton } from '@/components/utils';
import { ProductCardComponent, ProductViewFiltersComponent } from "@/components/catalogoEnLinea/purchaseFlow";
import { RequestStatusFeedbackComponent } from '@/components/catalogoEnLinea/purchaseFlow';
import { getQueryParamValue, injectObjectIntoQueryParams } from '@/utils/navigation';
import { PaginationHandler } from '@/utils/pagination-handler.js';
import PdfModal from '@/components/PdfModal.vue';
import { isNil, pick } from 'lodash';
import { 
    createLoadable, 
    toggleLoadable, 
    createPageable, 
    togglePageable, 
    setLoadableResponse, 
    setPageableResponse,
    isApiErrorResponse,
} from '@/utils/loadable.js';

export default {
  name: "ProductsView",
  components: {
    ProductCardComponent,
    SpinnerComponent,
    EmptyComponent,
    PaginationComponent,
    ProductViewFiltersComponent,
    RequestStatusFeedbackComponent,
    NavButton,
    PdfModal,
  },
  computed: {
    nombreFamilia() {
        if (this.familia.isLoading) return 'Cargando...';

        return this.familia.data.nombre;
    },
    circularFamilia() {
        return this.familia.data?.circular;
    },
  },
  data() {
    return {
        familyId: null,
        // data
        familia: createLoadable(null),
        products: createPageable([], 25),
        groups: createLoadable([]),
        subgroups: createLoadable([]),
        // filters
        filters: {
            id_subgrupo: getQueryParamValue(this.$route, 'id_subgrupo'),
            id_grupo: getQueryParamValue(this.$route, 'id_grupo'),
            nombre: getQueryParamValue(this.$route, 'nombre'),
        },
        // Pagination component
        pagination: new PaginationHandler(
            getQueryParamValue(this.$route, 'size') ?? 25,
            getQueryParamValue(this.$route, 'page') ?? 1,
        ),
        // Circulares
        visualizacionCircular: createLoadable(null),
        visualizacionCircularAbierta: false,
    }; 
  },
  methods: {
    async cargarFamilia() {
        toggleLoadable(this.familia);
        const { data } = await this.services.CmFamilias.buscarFamilia(this.familyId);
        setLoadableResponse(this.familia, data);
    },
    async fetchGroups() {
        toggleLoadable(this.groups);
        const { data } = await this.services.Group.getGroups({id_familia: this.familyId});
          setLoadableResponse(this.groups, data);
    },
    async fetchSubGroups() {
        toggleLoadable(this.subgroups);
        const { data } = await this.services.SubGroup.getSubGroups({id_familia: this.familyId, id_grupo: this.filters.id_grupo});
        setLoadableResponse(this.subgroups, data);
    },
    async fetchProducts() {
        togglePageable(this.products);
        const filters = { ...this.pagination.toApiFormat(), ...this.filters };
        const { data, headers } = await this.services.Product.getProductsByFamily(this.familyId, filters);
        setPageableResponse(this.products, data, headers);
    },
    handleFilterChange(name, value) {
        this.filters = { ...this.filters, [name]: value };
    },
    checkFiltersAreClean(keys) {
        for (let i = 0; i < keys.length; i++)  {
            if (!isNil(this.filters[keys[i]])) return false;
        }

        return true;
    },
    handleFilterReset() {
        const filtersClone = {... this.filters};
        const filtersKeys = Object.keys(filtersClone);

        if(this.checkFiltersAreClean(filtersKeys)) return;

        filtersKeys.forEach((filter) => {
            filtersClone[filter] = null;
        });

        this.filters = filtersClone;
    },
    async visualizarCircular() {
      this.visualizacionCircularAbierta = true;
      toggleLoadable(this.visualizacionCircular);
      const { data } = await this.services.CmFamilias.visualizarCircular(this.familyId);
      setLoadableResponse(this.visualizacionCircular, data, { isFile: true });

      if (isApiErrorResponse(data) && ['FAMILIA_NO_ENCONTRADA', 'FAMILIA_SIN_CIRCULAR'].includes(data.error.code)) {
        this.cargarFamilia();
      }
    },
    manejarVisibilidadVisualizacionCircular(visible) {
      this.visualizacionCircularAbierta = visible;
    },
  },
  watch: {
    pagination: {
        handler(meta) {
            injectObjectIntoQueryParams(pick(meta, ['size', 'page']));
            this.fetchProducts();
            this.$refs.wrapper.scrollIntoView();
        },
        deep: true,
    },
    filters: {
        handler(value) {
            injectObjectIntoQueryParams(value);
            this.fetchProducts();
        },
    },
    'filters.id_grupo' : {
        handler(value){
            this.filters.id_subgrupo = null;

            if (!value) {
                this.subgroups = createLoadable([]);
                return;
            }; 
            this.fetchSubGroups();
        },
        immediate: true,
    }
  },
  created() {
    const id = this.$route.params.familyId;
    this.familyId = id;
    this.cargarFamilia();
    this.fetchGroups();
    this.fetchSubGroups();
    this.fetchProducts();
  },
}
</script>
<style scoped>
    .products-list-goback-icon {
        margin: 16px 0 16px 0;
        font-size: 40px !important;
        cursor: pointer;
    }

    .product-list {
        list-style: none;
        width: 100%;
        padding: 0;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 16px;
        justify-items: stretch;
        align-items: stretch;
    }

    @media (min-width: 600px) {
        .product-list {
            grid-template-columns: repeat(2, 1fr);
        }
    }

    @media (min-width: 960px) {
        .product-list {
            grid-template-columns: repeat(3, 1fr);
        }
    }

    @media (min-width: 1264px) {
        .product-list {
            grid-template-columns: repeat(4, 1fr);
        }
    }

    @media (min-width: 1904px) {
        .product-list {
            grid-template-columns: repeat(6, 1fr);
        }
    }
</style>