import { isNil } from "lodash";

const isEmptyValue = (value) => {
    return (isNil(value) || value < 1 || !value);
}

const updateURL = (nextQueryParams) => {
    let queries = nextQueryParams.toString();
    if (queries) queries = '?' + queries;
    const newURL = `${window.location.pathname}${queries}`;
    window.history.replaceState({}, '', newURL);
}

export const injectQueryParam = (name, value) => {
    const nextQueryParams = new URLSearchParams(window.location.search);

    if (isEmptyValue(value)) {
        if (nextQueryParams.has(name)) nextQueryParams.delete(name);

        return updateURL(nextQueryParams);
    }

    nextQueryParams.set(name, value);
    updateURL(nextQueryParams);
};

export const getQueryParamValue = (route, name) => route.query[name];

export const injectObjectIntoQueryParams = (obj) => {
    const nextQueryParams = new URLSearchParams(window.location.search);

    for (const prop in obj) {
        if (isEmptyValue(obj[prop])) {
            if (nextQueryParams.has(prop)) nextQueryParams.delete(prop);
            continue;
        }

        nextQueryParams.set(prop, obj[prop]);
    }

    updateURL(nextQueryParams);
}
